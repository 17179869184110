import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Category, SubCategory } from 'src/app/shared/classes/category';
import { Product } from 'src/app/shared/classes/product';
import { CommonService } from 'src/app/shared/services/common.service';
import { Menu } from 'src/app/shared/services/nav.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  iframeSrc : SafeUrl;
  public menuName:string = 'name';
  public products: Product[] = [];
  public category: Category[] =[];
  public subCategory: SubCategory[] =[];

  public grid: string = 'col-xl-3 col-md-6';
  public layoutView: string = 'grid-view';
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public addItemCount = 8;
  public finished: boolean = false
  constructor(private route: ActivatedRoute, private router: Router,
    public serviceApiCall: ServerapicallService,public commonService: CommonService,
    private viewScroller: ViewportScroller, public productService: ProductService, private sanitizer: DomSanitizer) {   
      router.events.pipe(
        filter(event => event instanceof NavigationEnd)  
      ).subscribe((event: NavigationEnd) => {
         // Get Query params..
          this.menuName = this.route.snapshot.url[0].path.replace(/[^\w\s]/gi, " ");
          this.getCategoryDetails(this.menuName);

          // this.route.queryParams.subscribe(params => {
          //   this.sortBy = params.sortBy ? params.sortBy : 'ascending';
          //   this.pageNo = params.page ? params.page : this.pageNo;
          //   // Get Filtered Products..
          //   this.productService.getProducts.subscribe(response => {         
          //     // Sorting Filter
          //     this.products = this.productService.sortProducts(response, this.sortBy);
          //     // Paginate Products
          //     this.paginate = this.productService.getPager(this.products.length, +this.pageNo);     // get paginate object from service
          //     this.products = this.products.slice(this.paginate.startIndex, this.paginate.endIndex + 1); // get current page of items
          //   })
          // })
      });
  }

  ngOnInit(): void {
    
  }
  addItems() {
    if(this.subCategory.length == this.products.length){
      this.finished = true;
      return
    }
    this.products = this.subCategory.slice(0, this.addItemCount);
  }
  getCategoryDetails(name) {
    var inputParams: any = {
      Operation: "MID",
      Category: this.fnFormateInput(name)
    }
   
    this.serviceApiCall.postResponse(this.commonService.CategoryDetails, inputParams)
    .then((result: any) => {
      if(result.length !== 0){
        this.category = result;
        this.iframeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.category[0].bannerPath.toString());
        this.getSubCategory(this.category[0].id);
      }else{
        this.category=null;
        this.iframeSrc = null;
      }
    console.log(this.category);
    })
  }
  fnFormateInput(params) {
    var modelData: any = {
      Id:  0,
      Name: params,
      MenuId: 0,
      UploadType: undefined,
      BannerPath: undefined,
      Description: undefined,
      CreateBy: undefined,
      IsActive: undefined
    }
    return modelData;
  }

  getSubCategory(cateId){
    var inputParams: any = {
      Operation: "GET", SubCategory: this.fnFormateInputSub(cateId)
    }
    this.serviceApiCall.postResponse(this.commonService.SubCategoryDetails, inputParams)
    .then((result: any) => {
      if(result.length !== 0){
        this.subCategory = result;
        this.addItems();
      }else{
        this.subCategory=null;
      }
    console.log(this.subCategory);
    })
  }
  fnFormateInputSub(params) {
    var modelData: any = {
      Id:  0,
      Name: undefined,
      CategoryId: params,
      ImagePath: undefined,
      MetaKeyword: undefined,
      MetaDescription: undefined,
      IsActive: undefined,
      CreateBy: undefined,
      CreateDate: undefined
    }
    return modelData;
  }
  // SortBy Filter
  sortByFilter(value) {
    this.router.navigate([], { 
      relativeTo: this.route,
      queryParams: { sortBy: value ? value : null},
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }
  // Infinite scroll
  public onScroll() {
    // add another items
    this.addItemCount += 8;
    this.addItems();
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], { 
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    this.layoutView = value;
    if(value == 'list-view')
      this.grid = 'col-lg-12';
    else
      this.grid = 'col-xl-3 col-md-6';
  }

}
