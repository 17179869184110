import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Product, ProductList } from '../classes/product';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductResolverService implements Resolve<ProductList> {
  public product: ProductList = {};
  constructor(
    private router: Router,
    public productService: ProductService
  ) { }
  async resolve(route: ActivatedRouteSnapshot): Promise<ProductList> {
     this.productService.getProductBySKU(route.params.name).subscribe(product => {
      if(!product) { // When product is empty redirect 404
          this.router.navigateByUrl('/pages/404', {skipLocationChange: true});
      } else {
         this.product = product[0];
      }
    })
    await new Promise(resolve => setTimeout(resolve, 1000));   
    return this.product
  }
}
