import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/shared/classes/product';
import { NewProductSlider, ProducHandPickSlider, ProductDetailsMainSlider, ProductSlider } from 'src/app/shared/data/slider';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';

@Component({
  selector: 'app-spenio-home',
  templateUrl: './spenio-home.component.html',
  styleUrls: ['./spenio-home.component.scss']
})
export class SpenioHomeComponent implements OnInit {

  public products : any[] = [];
  public productCollections: any[] = [];
  public productData: any[] = [];
  constructor(public productService: ProductService, public serviceApiCall: ServerapicallService, 
    public commonService: CommonService) {
    this.getTopCollection();
    this.getSpecialProducts();
  //  Object.assign(this.products[0](productTypes))
    // this.productService.getProducts.subscribe(response => {
    //   this.products = response.filter(item => item.type == 'fashion');
    //   // Get Product Collection
    //   this.products.filter((item) => {
    //     item.collection.filter((collection) => {
    //       const index = this.productCollections.indexOf(collection);
    //       if (index === -1) this.productCollections.push(collection);
    //     })
    //   })
    // });
  }

  public ProductSliderConfig: any = ProducHandPickSlider;

  public sliders = [{
    title: 'welcome to fashion',
    subTitle: 'Men fashion',
    image: 'assets/images/slider/5.jpg'
  }, {
    title: 'welcome to fashion',
    subTitle: 'Women fashion',
    image: 'assets/images/slider/6.jpg'
  }];

  ngOnInit(): void {
    document.body.classList.add('box-layout-body');
   
  }

  ngOnDestroy() {
  	document.body.classList.remove('box-layout-body');
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  getTopCollection(){
    let name = "Imperial";
    var inputParams: any = {
      Operation: "G",
      Param: this.fnFormateInput(name)
    }
    this.serviceApiCall.postResponse(this.commonService.ProductList, inputParams)
    .then((result: any) => {
       if(result.length !== 0){
          this.productData = [result];
         // var randProducts = this.productData[0].productList[Math.floor(Math.random() * this.productData[0].productList.length)];
          var all_products = this.productData[0].productList;
          this.products = all_products.sort(() => 0.5 - Math.random());
          //this.products = all_products.sort(() => Math.random() - Math.random()).slice(0, 4)
         // this.addItems();
      }
    })
  }

  getSpecialProducts(){
    
    this.serviceApiCall.postResponse(this.commonService.SpecialProductCollections, '')
    .then((result: any) => {
       if(result.length !== 0){
          this.productCollections = result;
      }
    })
  }
  fnFormateInput(params) {
    var modelData: any = {
      Value: params
    }
    return modelData;
  }

  
}
