import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  readonly spenioGetMenus: string = environment.baseAPIUrl +"MenusMaster/menu-header";
  readonly CategoryDetails: string = "Category/get-details";
  readonly SubCategoryDetails: string = "Category/get-subcategory-by-menu";
  readonly ProductList: string = "Products/get-product-details";
  readonly RelatedProducts: string = "Products/get-related-product";
  readonly UserRegister: string = "Account/user-details-register";
  readonly UserLogin: string = "Account/user-login";
  readonly GetLoginDetails: string = "Account/login-user-details";
  readonly ZipCode: string = "Account/zip-code-details";
  readonly BillingDetails: string = "Account/billing-details";
  readonly GetBillingDetails: string = "Account/get-billing-details";
  readonly OrderDetails: string = "Order/user-create-order";
  readonly MyOrderDetails: string = "Order/my-order";
  readonly EmailSend: string = "Email/send";
  readonly UpdateCartDetails: string = "AddToCart/update-cart-details";
  readonly SpecialProductCollections: string = "Home/special-product";
  readonly AddWishListDetails: string = "AddToWishlist/wishlist-details";
  readonly GetWishListDetails: string = "AddToWishlist/get-wishlist-details";
  readonly DeleteWishListDetails: string = "AddToWishlist/delete-wishlist-details";
  readonly ProductEndPoint: string = "Products/get-product";
  readonly PasswordChange: string = "Account/password-change";
  readonly SetPassword: string = "Account/set-password";
  readonly SetWLPassword: string = "Account/set-password-without-login";
  
  readonly SearchData: string = "Products/get-search-data";
  readonly OrderUpdate: string = "Order/update-order";
  constructor(public modalService: NgbModal) { }
        replaceString(param: string) {
          return param.replace(/[^a-zA-Z ]/g, "")
                 .replace(' ','-')
                 .toLowerCase();
        }
        noSpecialAndAlphabetsCharacterAllowed(event: any) {
          const pattern = /[0-9\+\-\ ]/;
          let inputChar = String.fromCharCode(event.charCode);
        
              if (!pattern.test(inputChar) && event.charCode != '0') {
                  event.preventDefault();
              }
        }
}
