<div class="navbar">
  <a href="javascript:void(0)" (click)="leftMenuToggle()">
    <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
  </a>
  <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
    <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()" 
                                                         (mouseover)="onHover(false)"></a>
    <nav id="unset">
      <div (click)="leftMenuToggle()">
        <div class="sidebar-back text-left"><i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Back
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu">
        <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(menuItem.children ? true : false)">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" 
                (click)="toggletNavActive(menuItem)">
               {{menuItem.title}}
               <span class="sub-arrow"  *ngIf="menuItem.children"></span>
            </a>
            <!-- Link -->
            <a [routerLink]="['/', menuItem.path]" *ngIf="menuItem.type === 'link'">
               {{menuItem.title}}
               <span class="sub-arrow" *ngIf="menuItem.children"></span>
            </a>
           
          </li>
      </ul>
    </nav>
  </div>
</div>

