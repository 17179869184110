import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-custom-mobile-control',
  templateUrl: './custom-mobile-control.component.html',
  styleUrls: ['./custom-mobile-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomMobileControlComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CustomMobileControlComponent,
      multi: true
    }
  ]
})
export class CustomMobileControlComponent implements OnInit, ControlValueAccessor {

  @Input() code: string = "";
  @Input() invalid: boolean = false;
  @Input() disabled: boolean = false;
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();
  cmaxLength: number = 3;
  nmaxLength: number = 0;
  GSMIsValid: boolean = true;
  EnterIsValid: boolean = true;
  ErrorMsg: string = "";
  public countrycode = new FormControl('');
  public number = new FormControl('');
  private onChange: (mobilenumber: string) => void;
  private onTouch: () => void;
  numericPattern = '^[0-9]*$';
  gsmpattern = /(7|9|6|8)\d{7}/;
  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.code === null) {
        this.code = "";
        this.countrycode.setValue(this.code);
      }
      else
        if (this.code === "91") this.nmaxLength = 10;
      // this.number.setValidators([Validators.required, Validators.maxLength(this.nmaxLength)]);
      this.countrycode.setValue(this.code);
      this.onChange(this.countrycode.value + this.number.value);
    })
  }

  writeValue(obj: any): void {
    if (obj != null) {
      var inputno = String(obj);
      var Code = inputno.substring(0, 3);
      this.code = Code;
      var Number = inputno.substring(3, inputno.length);
      if (Code != "null")
        this.countrycode.setValue(Code);
      this.number.setValue(Number);

      if (this.number.value == "") {
        this.countrycode.setValue("");
        this.number.clearValidators();
        this.EnterIsValid = true;
        this.GSMIsValid = true;
      }
      else if (this.number.value[0] != "9" && this.number.value[0] != "7" && this.number.value[0] != "6" && this.number.value[0] != "8")
      {
        this.countrycode.setValue("");
        this.number.clearValidators();
        this.number.setValue("");
        this.EnterIsValid = true;
        this.GSMIsValid = false;
      }
      else if (this.countrycode.value === "91" && this.number.value[0] == "9" && this.number.value.length == 10) {
        this.number.clearValidators();
        this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
        this.EnterIsValid = true;
      }
      else if (this.countrycode.value === "91" && this.number.value[0] == "7" && this.number.value.length == 10) {
        this.number.clearValidators();
        this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
        this.EnterIsValid = true;
      }
      else if (this.countrycode.value === "91" && this.number.value[0] == "6" && this.number.value.length == 10) {
        this.number.clearValidators();
        this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
        this.EnterIsValid = true;
      }
      else if (this.countrycode.value === "91" && this.number.value[0] == "8" && this.number.value.length == 10) {
        this.number.clearValidators();
        this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
        this.EnterIsValid = true;
      }
      else {
        var tempnumber = this.number.value;
        this.number.setValue("");
        this.number.clearValidators();
        this.number.setValidators([Validators.maxLength(8), Validators.minLength(8), Validators.pattern(this.gsmpattern)]);
        this.number.setValue(tempnumber)
        this.ErrorMsg = "";
        this.ErrorMsg = "Please enter valid Phone number"
        this.GSMIsValid = true;
        this.EnterIsValid = false;
      }

    }
    else {
      this.number.setValue("");
      this.countrycode.setValue("");
      this.number.clearValidators();
      this.EnterIsValid = true;
      this.GSMIsValid = true;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.countrycode.disable();
      this.number.disable();
    }
    else {
      this.number.enable();
    }
  }

  validate(ctrl) {
    return this.number.errors;
  }

  doInput(event) {
    if (this.countrycode.value === "91" && this.number.value.length == 1) {
      if (this.number.value != "7" && this.number.value != "9" && this.number.value != "8" && this.number.value != "6") {
        this.countrycode.setValue("");
        this.number.setValue("");
        this.EnterIsValid = true;
        this.GSMIsValid = false;
        return false
      }
    }
    if (this.number.value.length == 0) {
      this.countrycode.setValue("");
    }
    else {
      if (this.code == "") this.code = "91";
      this.countrycode.setValue(this.code);
    }
    this.writeValue(this.countrycode.value + this.number.value);
    //this.validateinput(event)
    this.onChange(this.countrycode.value + this.number.value);
  }

  doBlur() {
    if (this.number.value.length == 0) {
      this.countrycode.setValue("");
    }
    else {
      if (this.code == "") this.code = "91";
      this.countrycode.setValue(this.code);
    }
    this.onTouch();
    this.blur.emit();
  }

  onlyNumeric(event: any) {
    try {
      if (this.number.value.length == 0) {
        if (this.code == "") this.code = "91";
        this.countrycode.setValue(this.code);
      }
      let ch = String.fromCharCode(event.keyCode);
      let regEx = new RegExp(this.numericPattern);
      if (regEx.test(ch)) {
        if (this.countrycode.value === "91" && this.number.value.length == 0) {
          if (ch == "7" || ch == "9" || ch == "6" || ch == "8") {
            this.GSMIsValid = true;
            return true
          }
          else {
            this.EnterIsValid = true;
            this.GSMIsValid = false;
            this.ErrorMsg = "";
            this.ErrorMsg = "Number should be valid"
            return event.preventDefault();
          }
        }
        else {
          this.GSMIsValid = true;
          return true;
        }
      }
      else return event.preventDefault();
    } catch (err) {
      console.log(err);
    }
  }

  validateinput(event) {
    if (event.target.value == "") {
      this.countrycode.setValue("");
      this.number.clearValidators();
      this.EnterIsValid = true;
      this.GSMIsValid = true;
    }
    else if (this.countrycode.value === "91" && event.target.value[0] == "9" && event.target.value.length == 10) {
      this.number.clearValidators();
      this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
      this.EnterIsValid = true;
    }
    else if (this.countrycode.value === "91" && event.target.value[0] == "7" && event.target.value.length == 10) {
      this.number.clearValidators();
      this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
      this.EnterIsValid = true;

    }
    else if (this.countrycode.value === "91" && event.target.value[0] == "6" && event.target.value.length == 10) {
      this.number.clearValidators();
      this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
      this.EnterIsValid = true;

    }
    else if (this.countrycode.value === "91" && event.target.value[0] == "8" && event.target.value.length == 10) {
      this.number.clearValidators();
      this.number.setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.gsmpattern)]);
      this.EnterIsValid = true;

    }
    else {
      this.number.clearValidators();
      this.number.setValidators([Validators.maxLength(8), Validators.minLength(8), Validators.pattern(this.gsmpattern)]);
      this.ErrorMsg = "";
      this.ErrorMsg = "Please enter valid number"
      this.GSMIsValid = true;
      this.EnterIsValid = false;
    }
  }
}
