import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { CategoryComponent } from './shop/category/category.component';
import { ProductListComponent } from './shop/product/product-list/product-list.component';
import { ProductDetailsComponent } from './shop/product/product-details/product-details.component';
import { Resolver } from './shared/services/resolver.service';
import { ProductResolverService } from './shared/services/product-resolver.service';
import { SpenioHomeComponent } from './home/spenio-home/spenio-home.component';
import { AccountComponent } from './account/account.component';

const routes: Routes = [
  {
    path: '',
    component: SpenioHomeComponent
  },
  {
    path: ':name',
    component: CategoryComponent
  },
  {
    path: 'products/list/:name',
    component: ProductListComponent
  },
  {
    path: 'product/details/:name',
    component: ProductDetailsComponent,
    resolve: {
      data: ProductResolverService
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },

  {
    path: 'shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: 'account',
    component:AccountComponent,
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'elements', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home/fashion',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
