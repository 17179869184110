import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from "../../../classes/product";
import { ProductService } from '../../../../shared/services/product.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit, OnDestroy  {

  @Input() product: any;
  @Input() currency: any;
 
  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public selectedSize: string = "";
  public selectedSku: string = "";
  public priceVarient: any[] = [];
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    private toaster: ToastrService,
    public productService: ProductService) { }

  ngOnInit(): void {
    if(this.product != null) {
      this.priceVarient = this.product.productVariant;
    }
    
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.QuickView, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  fnSelectSize(size: string){
    debugger;
    if(size != "0"){
      this.selectedSize = size;
      this.priceVarient = this.product.productVariant.filter(data=> data.sizes == size);
      Object.assign(this.product, {'selectedQty': this.counter || 1, 
      'selectedSize': this.selectedSize,
      'selectedSku': this.priceVarient[0].sku,
      'skuCode': this.priceVarient[0].sku,
      'selectedMrp': this.priceVarient[0].mrp,
      'selectedSalePrice': this.priceVarient[0].salePrice,
      'salePrice': this.priceVarient[0].salePrice
    });
    }
    else {
      this.selectedSize ="";
      this.priceVarient = this.product.productVariant;
      Object.assign(this.product, {'selectedQty': 1, 
      'selectedSize': '',
      'selectedSku': '',
      'skuCode': '',
      'selectedMrp': '',
      'selectedSalePrice': '',
      'salePrice': ''
      });
    }
  }

  // Increament
  increment() {
    this.counter++ ;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter-- ;
  }

  // Add to cart
  async addToCart(product: any) {
    debugger
    if(this.selectedSize != ""){
      //this.product.productVariant.filter(data=> data.sku == this.priceVarient[0].sku);
      const status = await this.productService.addToCart(product);
      if(status)
        this.router.navigate(['/shop/cart']);
      }else {
        this.toaster.warning("Please select the size")
      }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
