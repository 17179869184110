<div class="row margin_0">
    <div class="col-sm-4 padding_left padd_right_6">
      <input type="text" class="form-control"  disabled placeholder="91"
             />
    </div>
    <div class="col-sm-8 padding_0">
      <input type="text" class="form-control" [formControl]="number"  [ngClass]="{'is-invalid' : invalid}" placeholder="9810000000"
            [disabled]="setDisabledState(disabled)" (input)="doInput($event)" (blur)="doBlur()" (keypress)="onlyNumeric($event)"  (focusout)="validateinput($event)" maxlength="10"/>
    </div>
  </div>
  <span class="error-Span" *ngIf="!EnterIsValid">Please enter valid number</span>
  <span class="error-Span" *ngIf="!GSMIsValid">Please enter valid Phone number</span>
  
  