import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-email-control',
  templateUrl: './custom-email-control.component.html',
  styleUrls: ['./custom-email-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomEmailControlComponent),
    multi: true
  }]
})
export class CustomEmailControlComponent implements OnInit, ControlValueAccessor  {
  @Input() className: any;
  form: FormGroup;
  @Input() value: string = "";
  onChange: (value) => void;
  onTouched:() => void;
  disabled: boolean = false;
  valid: boolean = true;
  public emailid = new FormControl('');
  constructor(private fb: FormBuilder) {  }

ngOnInit() {
  }

  writeValue(value: string): void {
   // this.value = value ? value : '';
    this.emailid.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

 
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.emailid.disable();
    }
    else {
      this.emailid.enable();
    }
  }
  onKeyUpEvent(event: any) {
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.valid = emailPattern.test(event.target.value);
    if (event.target.value == "") {      
      return this.valid = true;    
    }
    else {  
      return this.valid;
    }
  }
}
