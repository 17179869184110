import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const loginState = {
  userLogin: JSON.parse(localStorage['loginName'] || '[]'),
}
@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private _session= new BehaviorSubject<any>({});
  public username: string;

  constructor() { }

  isLoggedIn(): boolean{
    return localStorage.getItem('username') == null ? false : true;
  }
  setLoginSession(session_data: any) {
    if(session_data && session_data.length > 0){
      localStorage.setItem('username', session_data[0].userName);
      localStorage.setItem('userid', session_data[0].id);
     }
  }
  loginUserName(): string {
    return localStorage.getItem('username');
  }
  loginUserId(): string {
    return localStorage.getItem('userid');
  }
  logout(){
      localStorage.removeItem('username');
      localStorage.removeItem('userid');
  }

  // public get userData(): Observable<any> {
  //   const itemsStream = new Observable(observer => {
  //     observer.next(loginState.userLogin);
  //     observer.complete();
  //   });
  //   return <Observable<any>>itemsStream;
  // }

  // get session() {
  //   return this._session.asObservable();
  // }
  // logOut(){

  // }
  // setLocalStorage(userData) {
  //   localStorage.setItem('currentUser', JSON.stringify(userData[0].userName));
  //   this.currentUserSubject.next(userData[0].userName);
  // }
}
