import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomValidatorService } from 'src/app/shared/services/custom-validator.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {
 
  public closeResult: string;
  public spinertype: string = "Please wait while your account is getting created";
  public modalOpen: boolean = false;
  public loginModal: boolean = false;
  @ViewChild("register", { static: false }) Register: TemplateRef<any>;
  @ViewChild("login") Login: LoginModalComponent;
  submitted: any = false;
  form: FormGroup;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,public fb: FormBuilder,
    private modalService: NgbModal, private spiner: NgxSpinnerService, private commonService: CommonService,
    private apiService: ServerapicallService, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.fnInitiateForm();
  }
  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.Register, { 
        size: 'lg',
        ariaLabelledBy: 'register-modal',
        centered: true,
        windowClass: 'Register' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
  fnInitiateForm() {
    this.form = new FormGroup({
      username: new FormControl('', Validators.required),
      mobileNumber: new FormControl('',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      email: new FormControl('',Validators.required),
      password: new FormControl('',[Validators.required]),
    })
  }
  get Reg(){  
    return this.form.controls;  
  }  
  fnSubmitForm() {
    this.submitted = true;
    if(this.form.valid) {
      this.spiner.show();
      var inputParams: any = {
        Operation: "I",
        UserDetails: this.FormateData(this.form.value)
      }
      this.apiService.postResponse(this.commonService.UserRegister,inputParams).then((responce: any)=> {
        if(responce[0].result === "Email Added") {
          this.modalService.dismissAll();
          this.toaster.success("Account successfully created");
          this.loginModal =true;
          this.form.reset();
          setTimeout(() => {
            this.spiner.hide();
            this.Login.openModal();
          }, 1000);
        }else{
          this.toaster.warning("Email already exist.");
          this.spiner.hide();
        }
        
      })
    }

  }
  FormateData(value: any) {
    var modalData: any = {
      Id: value.id || 0,
      UserName: value.username,
      Email: value.email,
      Password: value.password,
      MobileNumber: value.mobileNumber,
      IsActive: true
    }
    return modalData;
  }
  
noSpecialAndAlphabetsCharacterAllowed(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  let inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar) && event.charCode != '0') {
          event.preventDefault();
      }
}
}
