<app-header-three></app-header-three>

<app-breadcrumb [title]="menuName" [breadcrumb]="menuName"></app-breadcrumb>

<div class="breadcrumb-section">
    <section class="p-0">
        <iframe class="cat-iframe" [src]="iframeSrc" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        style="overflow:hidden;width:100%" scrolling="no" height="100%" width="100%"></iframe>
        
      </section>
    <!-- <div class="container-fluid">
        <iframe class="cat-iframe" [src]="iframeSrc" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            style="overflow:hidden;width:100%" scrolling="no" height="100%" width="100%" allowfullscreen></iframe>

         </div> -->
    <div class="container-fluid">
        <a [routerLink]="['/shop/collection/left/sidebar']">

            <!-- <img src="assets/images/collection-banner.jpg" class="img-fluid " alt=""> -->
        </a>
        <div class="row">
            <div class="top-banner-content small-section">
                <!-- <h4>{{category[0].name}}</h4> -->
                <div [innerHTML]="category[0]?.description"></div>
            </div>
        </div>
    </div>
</div>

<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="top-banner-wrapper">

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="collection-content col">
                    <div class="page-main-content">

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="collection-product-wrapper" id="products">
                                    <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView"
                                        [sortBy]="sortBy" (setGrid)="updateGridLayout($event)"
                                        (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                           
                                            <div class="col-grid-box" [ngClass]="grid"
                                                *ngFor="let subCate of subCategory">
                                                <div class="product-box">
                                                    <app-category-box 
                                                    [subCategory]="subCate" 
                                                    [loader]="true">
                                                    </app-category-box>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- Infinite Scroll -->
                                    <div infiniteScroll 
                                    [infiniteScrollDistance]="2"
                                    [infiniteScrollThrottle]="1000" 
                                    (scrolled)="onScroll()" 
                                    *ngIf="subCategory.length">
                                    </div>
                                    <div class="product-infinitescroll">
                                    <div class="theme-paggination-block">
                                        <div class="container-fluid p-0">
                                            <div class="row">
                                                <div class="text-center col-sm-12">
                                                    <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                        <img src="assets/images/loader.gif" *ngIf="!finished && products.length">
                                                        <div class="loading-more active" *ngIf="finished && products.length">No More Products</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                    <!-- <app-pagination
                                        [products]="products"
                                        [paginate]="paginate"
                                        (setPage)="setPage($event)">
                                    </app-pagination> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->