import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product, ProductList } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ToastrService } from 'ngx-toastr';
import { UserLoginService } from 'src/app/shared/services/user-login.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { LoginModalComponent } from '../../modal/login-modal/login-modal.component';


@Component({
  selector: 'app-product-box-spenio',
  templateUrl: './product-box-spenio.component.html',
  styleUrls: ['./product-box-spenio.component.scss']
})
export class ProductBoxSpenioComponent implements OnInit {

  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @ViewChild("login") Login: LoginModalComponent;
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string
  public imageVarient: any[] = [];
  constructor(private productService: ProductService,
    private toaster: ToastrService,
    public userService: UserLoginService,
    public serviceApiCall: ServerapicallService, private commonService: CommonService
    ) { }

  ngOnInit(): void {
    if(this.product != null){
      //this.imageVarient.push({ image: { src0: this.product.imagePath, src1: this.product.imagePath2, src2: this.product.imagePath3 }})
      this.imageVarient.push(this.product.imagePath);
      this.imageVarient.push(this.product.imagePath2);
      this.imageVarient.push(this.product.imagePath3);
    }
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    // for(let i =0; i < this.imageVarient.length; i++){
    //     console.log(this.imageVarient[i]);

    // }
    // console.log(this.imageVarient);
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    debugger;
    // we set default parameter
    Object.assign(product,{'skuCode': product.productVariant[0].sku});
    Object.assign(product,{'salePrice': product.productVariant[0].salePrice});
    Object.assign(product,{'selectedSize': product.productVariant[0].sizes});
    this.productService.addToCart(product);
  }

   // Add to Wishlist
   addToWishlist(product: any) {
     debugger;
    if(this.userService.isLoggedIn()){
      let userId = this.userService.loginUserId();
        Object.assign(product, 
          {'skuCode': product.productVariant[0].sku},
          {'selectedSku': product.productVariant[0].sku},
          {'salePrice': product.productVariant[0].salePrice},
          {'selectedSalePrice': product.productVariant[0].salePrice},
          {'selectedSize': product.productVariant[0].sizes},
          {'userId': userId },
          )
       var inputParams: any = {
         Operation: "I",
         AddToWishlistModel: this.fnFormatModel(product)
       }
       this.AddToWishlistDetails(inputParams);
     
    } else{
      this.Login.openModal();
     }
  }
 
  fnFormatModel(product: any) {
    var modelData: any = {
      Id: 0,
      ProductId: product.id,
      UserId: product.userId,
      SizeId: product.productVariant[0].id,
      Sku: product.productVariant[0].sku,
      Stock: product.qty,
      SelectedQty: product.selectedQty || 1,
      IsActive: true,
    }
    return modelData;
  }

  AddToWishlistDetails(inputParams: any) {
     this.serviceApiCall.postResponse(this.commonService.AddWishListDetails, inputParams)
    .then((data: any) => {
      if(data.result === 'Success'){
        this.toaster.success('Product has been added in wishlist.');
      }else if(data.result ==='EXIST') {
        this.toaster.warning('Product is aready on the wishlist.');
      }
      //this.productService.addToWishlist(product);
    })
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
}
