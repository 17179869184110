<app-header-three></app-header-three>
<app-breadcrumb [title]="productData[0]?.name" [breadcrumb]="productData[0]?.name"></app-breadcrumb>
<div class="collection-wrapper">
    <div class="container">
        <div class="row">
            <div class="collection-content col">
                <div class="page-main-content">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <!-- <a [routerLink]="['/shop/collection/left/sidebar']">
                                    <img src="assets/images/collection-banner.jpg" class="img-fluid " alt="">
                                </a> -->
                                <div class="top-banner-content small-section">
                                    <!-- <h4>{{productData[0].name}}</h4> -->
                                    <div [innerHTML]="productData[0]?.description"></div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-xl-12">
                                    <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                        <span class="filter-btn btn btn-theme">
                                            <i class="fa fa-filter" aria-hidden="true"></i> Filter
                                        </span>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <ul class="product-filter-tags">
                                <li class="mr-1" *ngFor="let tag of tags">
                                    <a href="javascript:void(0)" class="filter_tag">
                                        {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                    </a>
                                </li>
                                <li class="clear_filter" *ngIf="tags.length > 0">
                                    <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                                        Clear all
                                    </a>
                                </li>
                            </ul> -->
                            <div class="collection-product-wrapper" id="products">
                                <app-grid 
                                [products]="products" 
                                [paginate]="paginate" 
                                [layoutView]="layoutView" 
                                [sortBy]="sortBy" 
                                (setGrid)="updateGridLayout($event)" 
                                (setLayout)="updateLayoutView($event)" 
                                (sortedBy)="sortByFilter($event)">
                                </app-grid>
                                <div class="product-wrapper-grid" [ngClass]="layoutView">
                                    <div class="row">
                                        <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                            <div class="product-box">
                                                <app-product-box-spenio 
                                                [product]="product" 
                                                [onHowerChangeImage]="true"
                                                [thumbnail]="true"
                                                [cartModal]="true">
                                              </app-product-box-spenio>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!products.length">
                                            <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                            <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                                            <p>Please check if you have misspelt something or try searching with other words.</p>
                                            <!-- <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">continue shopping</a> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- Infinite Scroll -->
                                <div infiniteScroll 
                                    [infiniteScrollDistance]="2"
                                    [infiniteScrollThrottle]="1000" 
                                    (scrolled)="onScroll()" 
                                    *ngIf="products.length">
                                </div>
                                <div class="product-infinitescroll">
                                    <div class="theme-paggination-block">
                                        <div class="container-fluid p-0">
                                            <div class="row">
                                                <div class="text-center col-sm-12">
                                                    <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                        <img src="assets/images/loader.gif" *ngIf="!finished && products.length">
                                                        <div class="loading-more active" *ngIf="finished && products.length">No More Products</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>