import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';
import { UserLoginService } from 'src/app/shared/services/user-login.service';
import { ForgetPwdModalComponent } from '../forget-pwd-modal/forget-pwd-modal.component';
import { RegisterModalComponent } from '../register-modal/register-modal.component';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  public closeResult: string;
  public modalOpen: boolean = false;
  public spinertype: string = "Please wait. Credential verifying.";
  @ViewChild("login", { static: false }) Login: TemplateRef<any>;
  @ViewChild("register") Register: RegisterModalComponent;
  @ViewChild("forgot") Forgot: ForgetPwdModalComponent;
  form: FormGroup;
  submitted: any = false;
  isRegisterModalShow: any = false;
  isForgotModalShow: any = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,public fb: FormBuilder,
  private modalService: NgbModal, private spiner: NgxSpinnerService, 
  private commonService: CommonService,private apiService: ServerapicallService, 
  private toaster: ToastrService, private userStorage: UserLoginService,
  private product: ProductService) { }
  ngOnInit(): void {
    this.fnInitiateForm();
  }
 fnInitiateForm() {
    this.form = new FormGroup({
      email: new FormControl('',Validators.required),
      password: new FormControl('',[Validators.required]),
    })
  }
  fnSubmitForm() {
    this.submitted = true;
    if(this.form.valid){
      this.spiner.show();
      var inputParams: any = {
          Email: this.form.value.email, Password: this.form.value.password
      }
      this.apiService.postResponse(this.commonService.UserLogin,inputParams).then((result: any)=> {
        if(result.length > 0) {
          if(result[0].result == 'Exist'){
            this.userStorage.setLoginSession(result);
           setTimeout(() => {
            this.modalService.dismissAll();
            this.form.reset();
            this.product.UpdateAddToCart()
            this.spiner.hide();
          }, 1000);
          }else {
            this.spiner.hide();
            this.toaster.warning("Account doesn't exist.");
          }
        }else {
          this.spiner.hide();
          this.toaster.warning("Password confirmation doesn't match");
        }
      })
    }
  }
 
forgotPassword(){
  this.modalService.dismissAll();
  this.Forgot.openModal()
}
  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.Login, { 
        size: 'lg',
        ariaLabelledBy: 'login-modal',
        centered: true,
        windowClass: 'Login' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
}
