import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ServerapicallService {
  apiIssueMsg: any;
  constructor(public http: HttpClient, private spiner: NgxSpinnerService,
    public commonService: CommonService, private router: Router) { }

    getResponse(endpoint, formData: any) {
      return new Promise<any>((resolve, reject) => {
        this.http.get<any>(environment.baseAPIUrl + endpoint, formData).subscribe(
          res => {
            resolve(res);
          },
          err => {
            
            if (err.status == 400 || err.status == 500 || err.status == 0) {
              // this.translateMessage('SomethingWentWrong');
              // this.presentAlert(this.apiIssueMsg);
              reject(err);
            } else {
              reject(err);
            }
          }
        );
      });
    }
    getResponseData(endpoint) {
      return new Promise<any>((resolve, reject) => {
        this.http.get<any>(environment.baseAPIUrl + endpoint).subscribe(
          res => {
            resolve(res);
          },
          err => {
            
            if (err.status == 400 || err.status == 500 || err.status == 0) {
              // this.translateMessage('SomethingWentWrong');
              // this.presentAlert(this.apiIssueMsg);
              reject(err);
            } else {
              reject(err);
            }
          }
        );
      });
    }

    postResponse(endpoint, formData: any) {
      return new Promise<any>((resolve, reject) => {
        this.http.post(environment.baseAPIUrl + endpoint, formData
        ).subscribe(
          res => {
            resolve(res);
          },
          err => {
            if (err.status == 400 || err.status == 500 || err.status == 0) {
              // this.translateMessage('SomethingWentWrong');
              // this.presentAlert(this.apiIssueMsg);
              this.spiner.hide();
              reject(err);
            } else {
              reject(err);
              this.spiner.hide();
            }
            
          }
        );
      })
    }
}
