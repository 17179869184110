import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { json } from 'express';
import { ToastrService } from 'ngx-toastr';
import { Product, ProductList } from 'src/app/shared/classes/product';
import { LoginModalComponent } from 'src/app/shared/components/modal/login-modal/login-modal.component';
import { SizeModalComponent } from 'src/app/shared/components/modal/size-modal/size-modal.component';
import { NewProductSlider, ProducHandPickSlider, ProductDetailsMainSlider, ProductDetailsThumbSlider, ThumbnailSlider } from 'src/app/shared/data/slider';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';
import { UserLoginService } from 'src/app/shared/services/user-login.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  public product: any = {};
  public counter: number = 1;
  public activeSlide: any = 0;
  public selectedSize: string = "";
  public imageVarient: any[] = [];
  public sizeVarient: any[] = [];
  public categoryId: any;
  public priceVarient: any[] = [];
  public selectedData: any;
  public thumbImageShow: boolean = false;
  public activeBtn: any;
  public isCliked:boolean = false;
  form: FormGroup;
  @ViewChild("login") Login: LoginModalComponent;
  // @ViewChild("sizeChart") SizeChart: SizeModalComponent;

  public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
  public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;
  constructor(private route: ActivatedRoute, private router: Router,
    private toaster: ToastrService,
    public userService: UserLoginService,
    public serviceApiCall: ServerapicallService, private commonService: CommonService,
    public productService: ProductService) {
debugger;
      this.route.data.subscribe(response => this.product = response.data );
    }

  ngOnInit(): void {
    debugger;
    if(this.product != null) {
      if(this.product.imagePath !="")
        this.imageVarient.push(this.product.imagePath);
      if(this.product.imagePath2 !="")
        this.imageVarient.push(this.product.imagePath2);
      if(this.product.imagePath3 !="")
        this.imageVarient.push(this.product.imagePath3);
      if(this.product.imagePath4 !="")
        this.imageVarient.push(this.product.imagePath4);
      if(this.product.imagePath5 !="")
        this.imageVarient.push(this.product.imagePath5);
      if(this.product.imagePath6 !="")
        this.imageVarient.push(this.product.imagePath6);
      if(this.product.imagePath7 !="")
        this.imageVarient.push(this.product.imagePath7);

      if(this.imageVarient.length > 1)
        this.thumbImageShow = true;

      this.priceVarient = this.product.productVariant;
      //this.selectedData = this.priceVarient[0].sizes;
      //this.fnInitiateForm();
      this.categoryId = this.product.categoryId;
    }
  }

  // Increament
  increment() {
    this.counter++ ;
  }
  public ThumbnailSliderConfig: any = ThumbnailSlider;
  // Decrement
  decrement() {
    if (this.counter > 1) this.counter-- ;
  }

  selectSize(size){

  }

  fnSelectSize(size: string){
    debugger;
    if(size != "0"){
      this.selectedSize = size;
      this.isCliked = true;
      this.priceVarient = this.product.productVariant.filter(data=> data.sizes == size);
      Object.assign(this.product, {'selectedQty': this.counter || 1, 
      'selectedSize': this.selectedSize,
      'selectedSku': this.priceVarient[0].sku,
      'skuCode': this.priceVarient[0].sku,
      'selectedMrp': this.priceVarient[0].mrp,
      'selectedSalePrice': this.priceVarient[0].salePrice,
      'salePrice': this.priceVarient[0].salePrice
    });
    }
    else {
      this.selectedSize ="";
      this.priceVarient = this.product.productVariant;
      Object.assign(this.product, {'selectedQty': 1, 
      'selectedSize': '',
      'selectedSku': '',
      'skuCode': '',
      'selectedMrp': '',
      'selectedSalePrice': '',
      'salePrice': ''
      });
    }
  }
  // Add to cart
  async addToCart(product: any) {
    debugger
    if(this.selectedSize != ""){
      const status = await this.productService.addToCart(product);
      if(status)
        this.router.navigate(['/shop/cart']);
      }else {
        this.toaster.warning("Please select the size")
      }
  }

  // Buy Now
  async buyNow(product: any) {
    if(this.userService.isLoggedIn()){
      if(this.selectedSize != ""){
        Object.assign(product, {'selectedQty': this.counter || 1});
        Object.assign(product, {'selectedSize': this.selectedSize});
        const status = await this.productService.addToCart(product);
        if(status)
          this.router.navigate(['/shop/checkout']);
      }else {
        this.toaster.warning("Please select the size")
      }

    }
   else{
    this.Login.openModal();
   }
  }

  // Add to Wishlist
  addToWishlist(product: any) {
    if(this.userService.isLoggedIn()){
      let userId = this.userService.loginUserId();
      if(this.selectedSize != ""){
        Object.assign(product, 
          {'skuCode': this.priceVarient[0].sku},
          {'selectedSku': this.priceVarient[0].sku},
          {'salePrice': this.priceVarient[0].salePrice},
          {'selectedSalePrice': this.priceVarient[0].salePrice},
          {'selectedSize': this.priceVarient[0].sizes},
          {'userId': userId },
          )
       var inputParams: any = {
         Operation: "I",
         AddToWishlistModel: this.fnFormatModel(product)
       }
       this.AddToWishlistDetails(inputParams);
      }else {
        this.toaster.warning("Please select the size")
      }
    } else{
      this.Login.openModal();
     }
  }
 
  fnFormatModel(product: any) {
    var modelData: any = {
      Id: 0,
      ProductId: product.id,
      UserId: product.userId,
      SizeId: product.productVariant[0].id,
      Sku: product.productVariant[0].sku,
      Stock: product.qty,
      SelectedQty: product.selectedQty,
      IsActive: true,
    }
    return modelData;
  }

  AddToWishlistDetails(inputParams: any) {
     this.serviceApiCall.postResponse(this.commonService.AddWishListDetails, inputParams)
    .then((data: any) => {
      if(data.result === 'Success'){
        this.toaster.success('Product has been added in wishlist.');
      }else if(data.result ==='EXIST') {
        this.toaster.warning('Product is aready on the wishlist.');
      }
      //this.productService.addToWishlist(product);
    })
  }

}


