<!--modal popup start-->
<ng-template class="theme-modal" #forgot let-modal>
	<div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                    <div class="col-lg-6 col-xs-12" *ngIf="_emailForm">
                        <h3>Forgot Password</h3>
                        <div class="theme-card">
                            <fieldset [disabled] ="_emailFormDisabled">
                                <form [formGroup]="form" class="theme-form">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <app-custom-email-control formControlName="email"
                                        [className]="{'is-invalid' : submitted && (form.controls['email'] && form.controls['email'].errors)}">
                                    </app-custom-email-control>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-4">
                                            <button class="btn btn-solid" [disabled]="form.invalid" (click)="fnSubmitForm()">Submit</button>
                                        </div>
                                      </div>
                                </form>
                            </fieldset>
                        </div>
                    </div>
                
               
                <div class="col-lg-6 col-xs-12" *ngIf="_passwordForm">
                    <h3>Forgot Password</h3>
                    <div class="theme-card">
                        <form [formGroup]="passwordForm" (ngSubmit)="SubmitPassword()">
                            <div class="form-group">
                                <label for="newPassword">New Password</label>
                                <input type="text" class="form-control" id="newPassword" formControlName="pwd" minlength="8" maxlength="15" aria-describedby="emailHelp" autocomplete="off"
                                       [ngClass]="{'is-invalid' : submitted && (passwordForm.controls['pwd'] && passwordForm.controls['pwd'].errors)}">
                                <div *ngIf="f.pwd.touched && f.pwd.invalid" class="error-div">
                                    <div *ngIf="f.pwd.invalid">At least 8 or more characters</div>
                                    <div *ngIf="f.pwd.errors.required">Password is required.</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="confirmPassword">Confirm Password</label>
                                <input type="password" class="form-control" formControlName="cnf" id="confirmPassword" minlength="8" maxlength="15" autocomplete="off"
                                       [ngClass]="{'is-invalid' : submitted && (passwordForm.controls['cnf'] && passwordForm.controls['cnf'].errors)}">
                                <div *ngIf="f.cnf.touched && f.password.invalid" class="error-div">
                                    <div *ngIf="f.cnf.invalid">At least 8 or more characters</div>
                                    <div *ngIf="f.cnf.errors.required">Password is required.</div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-solid" [disabled]="passwordForm.invalid">Submit</button>
                        </form>
                    </div>
                </div>
               
                    <div class="col-lg-6 right-login">
                        <h3>Enter OTP</h3>
                        <fieldset [disabled] ="_otpFormDisabled">
                        <form [formGroup]="otpForm" (ngSubmit)="SubmitOtp()">
                            <div class="form-group">
                                <label for="enterOtp">Enter 6 digit OTP</label>
                                <input type="text" [ngClass]="message.type" class="form-control" id="enterOtp" formControlName="otp" placeholder="Enter OTP" aria-describedby="enterOtp" required>
                                <div *ngIf="message.isShown" [ngClass]="message.textColor">
                                  {{message.text}}
                                </div>
                              </div>
                              <div class="form-row">
                                <div class="col-4">
                                    <button class="btn btn-solid" [disabled]="form.invalid">Submit</button>
                                </div>
                              </div>
                        </form>
                    </fieldset>
                    </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- <app-register-modal #register></app-register-modal> -->

<app-common-spinner-control></app-common-spinner-control>
<!--modal popup end-->