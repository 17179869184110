<!--modal popup start-->
<ng-template class="theme-modal" #login let-modal>
	<div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6 col-xs-12">
                    <h3>Login</h3>
                    <div class="theme-card">
                        <form [formGroup]="form" class="theme-form">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <app-custom-email-control formControlName="email"
                                [className]="{'is-invalid' : submitted && (form.controls['email'] && form.controls['email'].errors)}">
                            </app-custom-email-control>
                            </div>
                            <div class="form-group">
                                <label for="review">Password</label>
                                <input type="password" class="form-control" formControlName="password" name="password" placeholder="Enter your password"
                                [ngClass]="{'is-invalid' : submitted && (form.controls['password'] && form.controls['password'].errors)}">
                            </div>
                            <div class="form-row">
                                <div class="col-4">
                                    <button class="btn btn-solid" [disabled]="form.invalid" (click)="fnSubmitForm()">Login</button>
                                </div>
                                <div class="col">
                                    <button class="btn button" (click)="forgotPassword()">Forgot password</button>
                                </div>
                              </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 right-login">
                    <h3>New Customer</h3>
                    <div class="theme-card authentication-right">
                        <h6 class="title-font">Create A Account</h6>
                        <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                        <button class="btn btn-solid" (click)="Register.openModal()">Create an Account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<app-register-modal #register></app-register-modal>
<app-forget-pwd-modal #forgot></app-forget-pwd-modal>

<app-common-spinner-control></app-common-spinner-control>
<!--modal popup end-->