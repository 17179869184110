import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServerapicallService } from '../../services/serverapicall.service';
import { CommonService } from '../../services/common.service';
import { Category } from '../../classes/category';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];
  public category: Category[] =[];
  iframeSrc : SafeUrl;
  @Input() menuItem:EventEmitter<string>= new EventEmitter();
  menuValue:any;
  constructor(private router: Router, public navServices: NavService,
    public serviceApiCall: ServerapicallService,public commonService: CommonService,private dataService: DataService) {

    this.navServices.getAllMenus().subscribe(data => this.menuItems = data)
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    
  }

  ngOnInit(): void {
    console.log(this.menuItems);
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }
  
  getCategoryDetails(menus) {
    this.getCategoryDetails(menus);
  }
  fnFormateInput(params) {
    var modelData: any = {
      Id:  0,
      Name: undefined,
      MenuId: params,
      UploadType: undefined,
      BannerPath: undefined,
      Description: undefined,
      CreateBy: undefined,
      IsActive: undefined
    }
    return modelData;
  }
}
