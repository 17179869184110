<div *ngIf="!loader">
    <div class="img-wrapper">
      <div class="lable-block">
        <!-- <span class="lable3" *ngIf="product.new">new</span>
        <span class="lable4" *ngIf="product.sale">on sale</span> -->
      </div>
      <div class="front">
        <a [routerLink]="['/product/details/', product.productVariant[0]?.sku]">
          <img 
            [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
            [lazyLoad]="ImageSrc ? ImageSrc : product.imagePath" 
            class="img-fluid lazy-loading" 
            alt="{{ product.name }}" />
        </a>
      </div>
      <div class="back" *ngIf="onHowerChangeImage">
        <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
          <img [src]="ImageSrc ? ImageSrc : product.imagePath" class="img-fluid lazy-loading" alt="{{ product.name }}">
        </a>
      </div>
      <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
        <li class="grid_thumb_img" [class.active]="ImageSrc == product.imagePath" *ngFor="let src of imageVarient">
          <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(src)">
            <img [lazyLoad]="src">
          </a>
        </li>
      </ul> -->
      <div class="cart-info cart-wrap">
        <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
          *ngIf="cartModal">
          <i class="ti-shopping-cart"></i>
        </a>
        <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
          *ngIf="!cartModal">
          <i class="ti-shopping-cart"></i>
        </a>
        <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
          <i class="ti-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
          <i class="ti-search" aria-hidden="true"></i>
        </a>
        <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
          <i class="ti-reload" aria-hidden="true"></i>
        </a> -->
      </div>
    </div>
    <div class="product-detail">
      <div>
        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        <a [routerLink]="['/product/details/', product.productVariant[0]?.sku]">
            <h6>{{ product?.name | titlecase }}</h6>
        </a>
        <!-- <p>{{ product?.description }}</p> -->
        <!-- <h4>
          {{ product.productVariant[0]?.salePrice * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
          <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
        </h4> -->
        <h4>
            {{ product.productVariant[0]?.salePrice | currency:currency?.currency:'symbol' }}
            <del><span class="money"> {{ product.productVariant[0]?.mrp }}</span></del>
          </h4>

        <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
          <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
            (click)="ChangeVariants(color, product)">
          </li>
        </ul> -->
      </div>
    </div>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  <app-quick-view #quickView [product]="product"></app-quick-view>
  <div *ngIf="!userService.isLoggedIn()">
    <app-login-modal #login></app-login-modal>
 </div>
  <app-cart-modal #cartModal [product]="product" *ngIf="cartModal"></app-cart-modal>
  
  