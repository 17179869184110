<!-- footer -->
<footer [ngClass]="class">
    <div class="white-layout" *ngIf="mainFooter">
        <section class="p-0">
            <div class="container-fluid">
                <div class="row footer-theme2 section-light footer-border">
                    <div class="col">
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title footer-mobile-title">
                                    <h4>about</h4>
                                </div>
                                <div class="footer-contant">
                                    <div class="footer-logo">
                                        <img [src]="themeLogo" alt="">
                                    </div>
                                    <div class="social-white">
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/speniohomefashion/"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li> -->
                                            <li>
                                                <a href="https://twitter.com/SpenioHome"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/speniohomefashion/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col form-p p-0">
                        <div class="footer-block">
                            <div class="subscribe-white">
                                <h2>newsletter</h2>
                                <form [formGroup]="form" (ngSubmit)="fnSubmitForm()">
                                    <div class="form-group">
                                        <!-- <input type="email" class="form-control" id="exampleFormControlInput"
                                            placeholder="Enter your email"> -->
                                            <app-custom-email-control formControlName="email"
                                                [className]="{'is-invalid' : submitted && (form.controls['email'] && form.controls['email'].errors)}">
                                            </app-custom-email-control>
                                        <button type="submit" [disabled]="form.invalid" class="btn btn-solid black-btn">subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title">
                                    <h4>store information</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul class="contact-details">
                                        <li>PLOT NO 12/6 DM ROAD NEXT TO OMAX, GREEN VALLY GURUKAL INDUSTRIAL AREA, FARIDABAD, HARYANA, INDIA
                                            PIN-121003</li>
                                        <li>Call Us: 9315043353</li>
                                        <li>Email Us: <a href="javascript:void(0)">support@speniohome.com</a></li>
                                        <li><a href="https://goo.gl/maps/KDhoi8HsgyvD8xhj6">Get Directions</a></li>
                                    </ul>
                                    <ul><li></li></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- <div class="white-layout box-layout" *ngIf="subFooter">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col">
                        <div class="footer-link link-white">
                            <div class="footer-title">
                                <h4>my account</h4>
                            </div>
                            <div class="footer-contant">
                                <ul>
                                    <li><a href="javascript:void(0)">mens</a></li>
                                    <li><a href="javascript:void(0)">womens</a></li>
                                    <li><a href="javascript:void(0)">clothing</a></li>
                                    <li><a href="javascript:void(0)">accessories</a></li>
                                    <li><a href="javascript:void(0)">featured</a></li>
                                    <li><a href="javascript:void(0)">service</a></li>
                                    <li><a href="javascript:void(0)">cart</a></li>
                                    <li><a href="javascript:void(0)">my order</a></li>
                                    <li><a href="javascript:void(0)">FAQ</a></li>
                                    <li><a href="javascript:void(0)">new product</a></li>
                                    <li><a href="javascript:void(0)">featured product</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div> -->
    <div class="sub-footer black-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p>
                            <i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Spenio. All rights reserved. Website powered by eShare Informatics
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <!-- <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li> -->
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->