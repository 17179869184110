import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';
import { UserLoginService } from 'src/app/shared/services/user-login.service';

@Component({
  selector: 'app-forget-pwd-modal',
  templateUrl: './forget-pwd-modal.component.html',
  styleUrls: ['./forget-pwd-modal.component.scss']
})
export class ForgetPwdModalComponent implements OnInit {

  public closeResult: string;
  form: FormGroup;
  passwordForm: FormGroup;
  submitted: any = false;
  otpForm: any = true;
  otpNumber: any;
  _emailForm: any = true;
  _emailFormDisabled = false;
  _otpForm: any = false;
  _otpFormDisabled: any = true;
  _passwordForm: any = false;

  public message: any = { type: '', text: '', textColor:'', isShown: false}

  public modalOpen: boolean = false;
  @ViewChild("forgot", { static: false }) Forgot: TemplateRef<any>;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,public fb: FormBuilder,
  private modalService: NgbModal, private spiner: NgxSpinnerService,
  private commonService: CommonService,private apiService: ServerapicallService, 
  private toaster: ToastrService, private userService: UserLoginService,
  private product: ProductService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('',Validators.required)
    })
    this.PasswordResetformIntialze();
    this.OtpformIntialze();
  }

  fnSubmitForm() {
    //this.IsOtpSend = true;
    this.spiner.show();
    var _otp = this.getSixDigitRandom();
    debugger;
    var inputParams: any = {
      UserId: 0,
      Email: this.form.get('email').value,
      Otp: _otp
    }
    this.apiService.postResponse(this.commonService.PasswordChange, inputParams)
    .then((data: any) => {
      this.spiner.hide();
       if(data.result.result == "False") {
         this.toaster.error("Email does not exist");
        this.message = {
          type: 'is-invalid',
          text: 'Email does not exist.',
          textColor:'invalid-feedback',
          isShown: true
        }
      }
      else if(data.result.result == "Success") {
        this.otpNumber = _otp;
        this.toaster.success("OTP has been sent to your registered email id");
        //this._otpForm = true;
        this._otpFormDisabled = false;
      }
    })
  }
  SubmitOtp() {
    if(this.otpForm.get('otp').value == this.otpNumber) {
      this.message = {
        type: 'is-valid',
        text: 'OTP has been matched',
        textColor:'valid-feedback',
        isShown: true
      }
      this.otpNumber = "";
      setTimeout(() => {
        this._emailForm = false;
        this._passwordForm = true;
        this._otpFormDisabled = true;
        this.message = { type: '', text: '', textColor:'', isShown: false}
      }, 1000);
    }else {
      this.message = {
        type: 'is-invalid',
        text: 'Invalid OTP',
        textColor:'invalid-feedback',
        isShown: true
      }
    }
  }
  getSixDigitRandom() {
    return Math.random().toString().substring(2, 8);
  }
  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.Forgot, { 
        size: 'lg',
        ariaLabelledBy: 'login-modal',
        centered: true,
        windowClass: 'Login' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  PasswordResetformIntialze() {
    this.passwordForm = new FormGroup({
      pwd: new FormControl('', Validators.required),
      cnf: new FormControl('', Validators.required)
    })
  }
  OtpformIntialze() {
    this.otpForm = new FormGroup({
      otp: new FormControl('', Validators.required)
    })
  }
  get f(){  
    return this.passwordForm.controls;  
  }
  SubmitPassword() {
    this.submitted = true;
    this.spiner.show();
    if(this.passwordForm.get('pwd').value == this.passwordForm.get('cnf').value) {
      var inputParams: any = {
        Key: this.form.get('email').value,
        Value: this.passwordForm.get('pwd').value
      }
      this.apiService.postResponse(this.commonService.SetWLPassword, inputParams)
      .then((data: any) => {
        if(data == true){
          setTimeout(() => {
            this._passwordForm = false;
            this._emailForm = true;
            this.form.reset();
            this.otpForm.reset();
            this.spiner.hide();
            this.modalService.dismissAll();
            this.toaster.success("Password has been changed");
            this.submitted = false;
          }, 2000);
        }else{
          this.spiner.hide();
          this.toaster.error("Something went wrong. Try again!");
        }
          
      })
    }else{
      this.toaster.warning("Password should be matched");
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
}
