<!--modal popup start-->
<ng-template class="theme-modal" #register let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg col-xs-12">
                    <h3>Register</h3>
                    <hr>
                    <div class="theme-card">
                        <form [formGroup]="form" (ngSubmit)="fnSubmitForm()" class="theme-form needs-validation">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="username">Your name<span>*</span></label>
                                    <input type="text" class="form-control" id="username" placeholder="Enter your name"
                                        formControlName="username" name="username"
                                        [ngClass]="{'is-invalid' : submitted && (form.controls['username'] && form.controls['username'].errors)}"
                                        required>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="mobileNumber">Mobile number</label>
                                    <input type="text" class="form-control" id="mobileNumber"
                                        placeholder="Enter number or email" formControlName="mobileNumber"
                                        name="mobileNumber" (keypress)="noSpecialAndAlphabetsCharacterAllowed($event)"
                                        [ngClass]="{'is-invalid' : submitted && (form.controls['mobileNumber'] && form.controls['mobileNumber'].errors)}"
                                        required>
                                    <div *ngIf="Reg.mobileNumber.touched && Reg.mobileNumber.invalid" class="error-div">
                                        <div *ngIf="Reg.mobileNumber.errors.required">Mobile Number is required.</div>
                                        <div *ngIf="Reg.mobileNumber.errors.pattern">Please, Enter 10 digit Mobile Number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="emailid">Email Id</label>
                                    <app-custom-email-control formControlName="email"
                                        [className]="{'is-invalid' : submitted && (form.controls['email'] && form.controls['email'].errors)}">
                                    </app-custom-email-control>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="password">Password</label>
                                    <input type="password" class="form-control" id="password"
                                        placeholder="Enter your password" formControlName="password" name="password"
                                        minlength="8"
                                        [ngClass]="{'is-invalid' : submitted && (form.controls['password'] && form.controls['password'].errors)}">
                                    <div *ngIf="Reg.password.touched && Reg.password.invalid" class="error-div">
                                        <div *ngIf="Reg.password.invalid">At least 8 or more characters</div>
                                        <div *ngIf="Reg.password.errors.required">Password is required.</div>
                                    </div>
                                    <app-password-strength-bar [passwordToCheck]="form.value.password">
                                    </app-password-strength-bar>
                                </div>
                            </div>
                            <button class="btn btn-solid" [disabled]="form.invalid">Register</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<app-common-spinner-control></app-common-spinner-control>
<div *ngIf="loginModal">
    <app-login-modal #login></app-login-modal>
</div>

<!--modal popup end-->