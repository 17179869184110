import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-footer-three',
  templateUrl: './footer-three.component.html',
  styleUrls: ['./footer-three.component.scss']
})
export class FooterThreeComponent implements OnInit {

  @Input() class: string; // Default class 
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo

  public today: number = Date.now();
  form: FormGroup;
  submitted: any = false;
  
  constructor(private toaster: ToastrService,) { }

  ngOnInit(): void {
    this.fnInitiateForm();
  }
  fnSubmitForm() {
   
    this.submitted = true;
    if (this.form.invalid) {
      this.toaster.error("Enter valid email id");
      return;  
    }
    setTimeout(() => {
      this.form.reset();
      this.submitted = false;
      this.toaster.success("Email Registered Successfully");
    }, 500);

  }

  fnInitiateForm() {
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.form = new FormGroup({
      email: new FormControl('',[Validators.pattern(emailPattern), Validators.required])
    })
  }
}
