import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { LoginModalComponent } from '../../components/modal/login-modal/login-modal.component';
import { CommonService } from '../../services/common.service';
import { ProductService } from '../../services/product.service';
import { ServerapicallService } from '../../services/serverapicall.service';
import { UserLoginService } from '../../services/user-login.service';

@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit {

  @Input() class: string = 'header-2';
  public product: any;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @ViewChild("login") Login: LoginModalComponent;
  public stick: boolean = false;
  loginName: any;
  userLoggedIn: any;
  constructor(public loginService: UserLoginService,private productService: ProductService, public serviceApiCall: ServerapicallService, 
    private commonService: CommonService) {
    
   }
 
  ngOnInit(): void {
    //this.getWishlistProducts();
  }


  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }


}
