<!-- Add to cart modal popup start-->
<ng-template #cartModal let-modal>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="modal-bg addtocart">
                        <button type="button" id="close-cart-modal" class="close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="media">
                            <div class="media-body align-self-center text-center">
                                <div class="buttons">
                                    <a [routerLink]="['/shop/cart']" class="view-cart btn btn-solid">Your cart</a>
                                    <a (click)="checkOut()" class="checkout btn btn-solid">Check out</a>
                                    <a (click)="modal.dismiss('Cross click')" class="continue btn btn-solid">Continue shopping</a>
                                </div>
                            </div>
                        </div>

                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<div *ngIf="!userService.isLoggedIn()">
    <app-login-modal #login></app-login-modal>
  </div>
<!-- Add to cart modal popup end-->