<app-header-three [sticky]="true"></app-header-three>
<!-- <app-breadcrumb [title]="'Dashboard'" [breadcrumb]="''"></app-breadcrumb> -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn" style="color: white;">
                        my account
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li [ngClass]="{'active': tab==='tab1'}"><a (click)="onClick(1)" [routerLink]="['/account/my-orders']">My Orders</a></li>
                            <li [ngClass]="{'active': tab==='tab3'}"><a [routerLink]="['/account/info']" (click)="onClick(3)"href="javascript:void(0)">Address Book</a></li>
                            <li [ngClass]="{'active': tab==='tab4'}"><a [routerLink]="['/account/wishlist']" (click)="onClick(4)"href="javascript:void(0)">My Wishlist</a></li>
                            <li [ngClass]="{'active': tab==='tab5'}" class="last"><a [routerLink]="['/account/change-password']" (click)="onClick(5)"href="javascript:void(0)">Change Password</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>
<app-footer-three></app-footer-three>