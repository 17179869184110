import { Component, OnInit, Injectable, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product, ProductList } from "../../classes/product";
import { ServerapicallService } from '../../services/serverapicall.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { UserLoginService } from '../../services/user-login.service';
import { LoginModalComponent } from '../modal/login-modal/login-modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: any = [];
  public search: boolean = false;
  public searchDataList: any = [];
  @ViewChild("login") Login: LoginModalComponent;
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  public serviceApiCall: ServerapicallService, 
    public commonService: CommonService,
    private router: Router,
    private translate: TranslateService,
    public userService: UserLoginService,
    public productService: ProductService) {
    this.productService.cartItems.subscribe(response => this.products = response);
    
  }

  ngOnInit(): void {
    this.getSearchData();
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  public search2 = '';

  selectedTempStatic(item) {
    this.search2 = item.name;
    const url = this.router.createUrlTree(['/product/details', item.sku])
    window.open(url.toString(), '_blank')
  }
  getSearchData(){
    this.serviceApiCall.postResponse(this.commonService.SearchData, '')
    .then((result: any) => {
       if(result.length !== 0){
          this.searchDataList = result;
      }
    })
  }
  public checkOut() {
    if(this.userService.isLoggedIn()) 
      this.router.navigate(['/shop/checkout']);
    else
      this.Login.openModal();
    
   }

}
