import { Component, Input, OnInit } from '@angular/core';
import { ProductList } from 'src/app/shared/classes/product';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ServerapicallService } from 'src/app/shared/services/serverapicall.service';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit {

  @Input() categoryId: any;
  public products: ProductList[] = [];

  constructor(public serviceApiCall: ServerapicallService, public commonService: CommonService) { 
   
  }
 

  ngOnInit(): void {
  }
  ngOnChanges(){
    var inputParams: any = {
      Operation: "G",
      Param: this.fnFormateInput(this.categoryId)
    }
    this.serviceApiCall.postResponse(this.commonService.RelatedProducts, inputParams)
    .then((result: any) => {
       if(result.length !== 0){
          this.products = result;
      }
    })
  }
  fnFormateInput(type: string) {
    var modelData: any = {
      Value: type
    }
    return modelData;
  }
}
