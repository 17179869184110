import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Category } from '../classes/category';
import { CommonService } from './common.service';
import { ServerapicallService } from './serverapicall.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _categoty = new BehaviorSubject<Category[]>([]);
  private dataStore: { categories: Category[] } = { categories: [] };
  constructor(public serviceApiCall: ServerapicallService,public commonService: CommonService,) { }

  get categories() {
    return this._categoty.asObservable();
  }

  getCategoryDetails(menus) {
    var title = menus.title;
    var inputParams: any = {
      Operation: "MID",
      Category: this.fnFormateInput(title)
    }
    this.serviceApiCall.postResponse(this.commonService.CategoryDetails, inputParams)
    .then((result: any) => {
       this.dataStore.categories = result;
       this._categoty.next(Object.assign({}, this.dataStore).categories);
      // this.category = result;
      // var vdoString = this.category[0].bannerPath.toString();
      // this.iframeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.category[0].bannerPath.toString());
      // console.log(this.category);

    })
  }
  fnFormateInput(params) {
    var modelData: any = {
      Id:  0,
      Name: undefined,
      MenuId: params,
      UploadType: undefined,
      BannerPath: undefined,
      Description: undefined,
      CreateBy: undefined,
      IsActive: undefined
    }
    return modelData;
  }
}
