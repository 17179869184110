import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  public openDashboard: boolean = false;

  tab : any = 'tab1';
  tab1 : any; tab2 : any; tab3 : any; tab4 : any; tab5 : any
  Clicked : boolean

  constructor() { 
  }

  ngOnInit(): void {
  }

  ToggleDashboard() {
    this.openDashboard = !this.openDashboard;
  }
 

  onClick(check){
        if(check==1){
          this.tab = 'tab1';
        }else if(check==2){
          this.tab = 'tab2';
        }else if(check==3){
          this.tab = 'tab3';
        }else if(check==4){
          this.tab = 'tab4';
        }else{
          this.tab = 'tab5';
        }    
    }
}
